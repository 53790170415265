import { conf } from 'outlinejs';
import { BaseModel } from '../core/models';

export class CartPaymentMethod extends BaseModel {
  get urlRoot() {
    return conf.settings.PROFESSIONAL_CART_PAYMENT_METHOD;
  }

  get idAttribute() {
    return 'paymentType';
  }

  get paymentType() {
    return this.get('paymentType');
  }

  get name() {
    return this.get('name');
  }

  fetch(options = {}) {
    const params = {
      data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE }
    };
    options = Object.assign(params, options);
    return super.fetch(options);
  }
}
