import { runtime } from '@outlinejs/contexts';
import { conf } from 'outlinejs';

import { GuiNotification } from '../core/notifications';
import { EpocaBaseController } from '../core/controllers';

import { PaymentLayoutView, PaymentView, PaymentCheckView } from './views';
import { CartPaymentMethodCollection } from './managers';
import Logger from '../core/logger';
import { ProfessionalOrderHeader } from '../orders/models';
import EventTracker from '../core/eventTracker';
import { CustomError } from '../core/errors';

export class PaymentController extends EpocaBaseController {
  get layoutView() {
    return PaymentLayoutView;
  }

  get view() {
    return PaymentView;
  }

  get context() {
    return Object.assign(super.context, {
      paymentMethods: this.paymentMethods,
      alertMessage: this.alertMessage
    });
  }

  get paymentRedirectUri() {
    return `${window.location.origin}/payment/${this.cartHeader.model.id}/check?orderGuid=${this.cartHeader.model.orderGuid}`;
  }

  async initContentProps() {
    await super.initContentProps();

    this.paymentMethods = {
      collection: new CartPaymentMethodCollection(),
      isLoading: false
    };

    // const alertMessages = {
    //   it:
    //     'Il circuito di pagamento <b>American Express</b> non è al momento disponibile.<br/>Ci scusiamo per il disagio.',
    //   en:
    //     'The <b>American Express</b> payment system is currently unavailable.<br/>We apologize for the inconvenience.',
    //   es:
    //     'El sistema de pago de <b>American Express</b> no está disponible actualmente.<br/>Nos disculpamos por las molestias.'
    // };
    // this.alertMessage = alertMessages[this.request.language];
    this.alertMessage = undefined;
  }

  async init(cartGuid) {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      if (this.user && this.customerUser) {
        if (!this.request.customerUserIsAuthorized) {
          this.response.navigate('unauthorized');
          return;
        }

        await this.loadCartHeader();

        this.isBlockingMessageActive();

        this.onEnterMessageModalIsActive();

        this.startLoadingPage();

        await this.loadCurrentOrderHeader(this.cartHeader.model.id);

        this.stopInitialRendering();

        const { shopCode } = this.customerUser;
        this.paymentMethods.collection = await this.paymentMethods.collection.fetch({
          data: { shopCode, cartGuid }
        });

        this.stopLoadingPage();
      }
    }
  }

  async confirmOrder(paymentType) {
    this.startLoadingPage();

    EventTracker.log(this.customerUser, 'payment_select', {
      online_payment: true,
      payment_type_id: paymentType
    });

    let orderHeader = this.orderHeader.model;
    if (!orderHeader) {
      orderHeader = await this.createOrderHeader();
      if (!orderHeader) {
        GuiNotification.modalError(
          this.i18n.gettext("Non è stato possibile confermare l'ordine: ") +
            this.cartHeader.model.orderGuid,
          'Error detail: PaymentController.createOrderHeader'
        );
        this.stopLoadingPage();
        return false;
      }
    }

    orderHeader = await this.updatePaymentType(paymentType, this.cartHeader.model.id);
    if (!orderHeader) {
      GuiNotification.modalError(
        this.i18n.gettext("Non è stato possibile confermare l'ordine: ") +
          this.cartHeader.model.orderGuid,
        'Error detail: PaymentController.updatePaymentType'
      );
      this.stopLoadingPage();
      return false;
    }

    window.location.href = this.orderHeader.model.paymentUrl;
  }

  async createOrderHeader() {
    // we need to initialize model to force POST
    this.orderHeader.model = new ProfessionalOrderHeader();
    this.orderHeader.model.cartGuid = this.cartHeader.model.id;
    this.orderHeader.model.shopCode = this.customerUser.shopCode;

    let order_id;
    let payment_type_id;
    try {
      this.orderHeader.isLoading = true;
      this.orderHeader.model = await this.orderHeader.model.create();
      order_id = this.orderHeader.model.orderId;
      payment_type_id = this.orderHeader.model.paymentType;
    } catch (err) {
      const error = new CustomError(err);

      Logger.error('PaymentController.createOrderHeader', {
        err,
        context: this.context,
        orderHeader: this.orderHeader,
        cartHeader: this.cartHeader
      });

      EventTracker.log(this.customerUser, 'payment_error', {
        online_payment: true,
        order_id,
        payment_type_id,
        error_code: error.name
      });

      this.orderHeader.isLoading = false;
      return null;
    }

    this.orderHeader.isLoading = false;

    return this.orderHeader;
  }

  async updatePaymentType(paymentType, cartGuid) {
    if (!this.orderHeader.model) {
      this.orderHeader.model = new ProfessionalOrderHeader();
    }

    this.orderHeader.model.paymentType = paymentType;
    this.orderHeader.model.redirectUri = this.paymentRedirectUri;
    this.orderHeader.model.cartGuid = cartGuid;

    let order_id;

    try {
      this.orderHeader.isLoading = true;
      this.orderHeader.model = await this.orderHeader.model.save();

      order_id = this.orderHeader.model.orderId;
    } catch (err) {
      const error = new CustomError(err);

      Logger.error('PaymentController.updatePaymentType', {
        err,
        context: this.context,
        orderHeader: this.orderHeader,
        cartHeader: this.cartHeader
      });

      EventTracker.log(this.customerUser, 'payment_error', {
        online_payment: true,
        order_id,
        payment_type_id: paymentType,
        error_code: error.name
      });

      this.orderHeader.isLoading = false;
      return null;
    }

    this.orderHeader.isLoading = false;

    return this.orderHeader;
  }

  backToCart() {
    this.navigateToCheckoutView();
  }
}

export class PaymentCheckController extends EpocaBaseController {
  get layoutView() {
    return PaymentLayoutView;
  }

  get view() {
    return PaymentCheckView;
  }

  get context() {
    return Object.assign(super.context, {});
  }

  async initContentProps() {
    await super.initContentProps();
  }

  async init() {
    await this.initContentProps();

    this.startInitialRendering();

    if (this.request.query && this.request.query.status) {
      const orderGuid = this.request.query.orderGuid || null;
      const payServiceMessage = this.request.query.message || null;
      let payment_type_id = undefined;
      let order_id = undefined;

      try {
        this.orderHeader = new ProfessionalOrderHeader({ id: orderGuid });
        await this.orderHeader.fetch();

        payment_type_id = this.orderHeader.paymentType;
        order_id = this.orderHeader.orderId;
      } catch (error) {
        Logger.error('PaymentCheckController.init - Unable to retrieve payment type', {
          error,
          orderGuid,
          payServiceMessage
        });
      }

      if (this.request.query.status === conf.settings.PAYMENT_OK) {
        EventTracker.log(this.customerUser, 'payment_success', {
          online_payment: true,
          payment_type_id,
          order_id
        });

        return this.navigateToOrderConfirmationView(orderGuid);
      }

      if (this.request.query.status === conf.settings.PAYMENT_KO) {
        EventTracker.log(this.customerUser, 'payment_error', {
          online_payment: true,
          payment_type_id,
          order_id,
          error_code: payServiceMessage || 'GENERAL_ONLINE_PAYMENT_ERROR'
        });

        return this.navigateToCheckoutView();
      }

      Logger.error('PayService Status is mandatory', {
        request: this.request,
        payServiceMessage
      });
    }
  }
}
