import { routers, routing } from 'outlinejs';

import { PaymentController, PaymentCheckController } from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('payment:main', PaymentController),
      ':cartGuid:': routing.url('payment:main', PaymentController),
      ':cartGuid:/check': routing.url('payment:check', PaymentCheckController)
    };
  }
}
