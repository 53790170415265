import { conf } from 'outlinejs';
import { BaseCollection } from '../core/managers';

import { CartPaymentMethod } from './models';

export class CartPaymentMethodCollection extends BaseCollection {
  get name() {
    return 'CartPaymentMethod';
  }

  get url() {
    return conf.settings.PROFESSIONAL_CART_PAYMENT_METHOD;
  }

  get model() {
    return CartPaymentMethod;
  }
}
