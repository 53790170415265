import React from 'react';

import { EpocaBaseContentView, EpocaBaseLayoutView } from '../core/views';
import { PaymentButton } from './components';

import './styles/main.scss';

export class PaymentLayoutView extends EpocaBaseLayoutView {
  get showHeader() {
    return false;
  }

  get showFooter() {
    return false;
  }
}

/**
 * Payment View.
 *
 * NOTE: render method is defined in EpocaBaseContentView
 * */
export class PaymentView extends EpocaBaseContentView {
  confirmOrder(paymentType) {
    this.props.delegate.confirmOrder(paymentType);
  }

  backToCart() {
    this.props.delegate.backToCart();
  }

  renderContent() {
    const paymentChoiceTextInfo = this.i18n.gettext('To complete order select payment type');
    const secureText = this.i18n.gettext(
      'All transactions are secure and guaranteed by international encryption certificates.'
    );
    const backBtnLabel = this.i18n.gettext('Back');

    const { paymentMethods, alertMessage } = this.props;

    return (
      <div className="payment-container">
        <div className="row">
          <div className="col-sm-12">
            <div className="payment-header">
              <div className="logo" />
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="payment-content">
              <h4>{paymentChoiceTextInfo}</h4>
              <div className="row payment-methods">
                <div className="col-sm-12">
                  {paymentMethods.collection && paymentMethods.collection.models
                    ? paymentMethods.collection.models.map((paymentMethod) => (
                        <PaymentButton
                          key={paymentMethod.paymentType}
                          btnClass={paymentMethod.paymentType}
                          action={this.confirmOrder.bind(this, paymentMethod.paymentType)}
                        />
                      ))
                    : null}
                </div>
                {alertMessage && (
                  <div className="col-sm-12">
                    <div
                      className="alert alert-danger"
                      dangerouslySetInnerHTML={{
                        __html: alertMessage
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="row payment-back-button">
                <div className="col-sm-12">
                  <div className="item-button back-button">
                    <button
                      className="btn btn-default btn-block back"
                      data-action=""
                      onClick={this.backToCart.bind(this)}>
                      {backBtnLabel}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="payment-footer">
              <div className="secure-image">
                <a target="_blank" href="http://www.rapidssl.com" rel="noreferrer">
                  &nbsp;
                </a>
              </div>
              <div className="secure-text">{secureText}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class PaymentCheckView extends EpocaBaseContentView {
  renderContent() {
    return <div />;
  }
}
