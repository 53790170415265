import React from 'react';
import PropTypes from 'prop-types';

import { components } from 'outlinejs';

/**
 * Cart Projects item buttons
 */
export class PaymentButton extends components.BaseComponent {
  render() {
    const { btnClass, label, action, dataAction } = this.props;
    return (
      <div className={`item-button payment-button ${btnClass}`}>
        <button className="btn btn-block btn-ae-icon" onClick={action} data-action={dataAction}>
          {label}
        </button>
      </div>
    );
  }
}

PaymentButton.propTypes = {
  btnClass: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  label: PropTypes.string,
  dataAction: PropTypes.string
};

PaymentButton.defaultProps = {
  btnClass: '',
  label: null,
  dataAction: ''
};
